<template>
  <div class="sampledetail">
    <div class="title mt10">基础信息</div>
    <van-cell-group class="mt10">
      <van-field label="检测状态" input-align="right" readonly v-show="dataList.check_status == 0" value="待检测" />
      <van-field label="检测状态" input-align="right" readonly v-show="dataList.check_status == 1" value="检测中" />
      <van-field label="检测状态" input-align="right" readonly v-show="dataList.check_status == 2" value="初检完成" />
      <van-field label="检测状态" input-align="right" readonly v-show="dataList.check_status == 3" value="检测完成" />
      <van-field label="检测状态" input-align="right" readonly v-show="dataList.check_status == 4" value="让步审批中" />
      <van-field label="检测状态" input-align="right" readonly v-show="dataList.check_status == 5" value="审批完成" />
      <van-field label="报检类型" input-align="right" readonly :value="dataList.inspect.type.type_name" />
      <van-field label="报检编号" input-align="right" readonly :value="dataList.inspect.inspect_code" />
      <van-field label="报检时间" input-align="right" readonly :value="dataList.inspect.inspect_time" />
    </van-cell-group>
    <div class="title">报检员信息</div>
    <van-cell-group class="mt10">
      <van-field label="报检员ID" input-align="right"   readonly :value="dataList.inspect.inspect_emp_id" />
      <van-field label="报检员姓名" input-align="right" readonly :value="dataList.inspect.inspect_emp_name" />
      <van-field label="报检员部门" input-align="right" readonly :value="dataList.inspect.inspect_dep_name" />
    </van-cell-group>
    <div class="title">样品信息</div>
    <van-cell-group class="mt10">
      <van-field label="产品编号" input-align="right" readonly :value="dataList.inspect.sample_code" />
      <van-field label="产品名称" input-align="right" readonly :value="dataList.inspect.sample_name" />
      <van-field label="产品规格型号" input-align="right" readonly :value="dataList.inspect.sample_specs" />
      <van-field label="报检批次" input-align="right" readonly :value="dataList.inspect.inspect_batch" />
      <van-field label="产品供应商" input-align="right" readonly :value="dataList.inspect.supplier_name" />
      <van-field label="合同编号" input-align="right" readonly :value="dataList.inspect.contract_no" />
      <van-field label="原产品" input-align="right" readonly :value="dataList.inspect.original_product" />
      <van-field label="生产线" input-align="right" readonly :value="dataList.inspect.production_line" />
      <van-field label="报检重要说明" input-align="right" readonly :value="dataList.inspect.inspect_remark" />
    </van-cell-group>
    <div class="title">检测情况</div>
    <van-cell-group class="mt10">
      
      <van-cell title="检样员" :value="dataList.check_emp_name" />
      <van-cell title="初检说明" :value="dataList.check_remark" />
      <van-cell title="初检时间" v-show="dataList.check_status > 1" :value="dataList.check_time" />                         
      <van-cell title="复检员" :value="dataList.recheck_emp_name" />
      <van-cell title="复检说明" v-show="dataList.check_status > 1" :value="dataList.recheck_remark" />
      <van-cell title="复检时间" v-show="dataList.check_status > 2" :value="dataList.recheck_time" />    
      <van-field label="检测设备" input-align="right" readonly :value="equipmentName" />             
      <van-field label="检样员"  input-align="right" readonly :value="dataList.check_emp_name" />
      <van-field label="是否复检" input-align="right" readonly v-show="dataList.is_inspect_recheck == 0" value="否" />
      <van-field label="是否复检" input-align="right" readonly v-show="dataList.is_inspect_recheck == 1" value="是" />                              
    </van-cell-group>    
    <div class="title">检测项目</div>
    <div style='position:relative;'> 
      <!-- <img v-show="dataList.conclusion == 1" src="@/assets/hg.png" alt="合格" class="status" />
      <img v-show="dataList.conclusion == 0" src="@/assets/bhg.png" alt="不合格" class="status" /> -->
      <van-icon class="status" v-if="dataList.conclusion == 1" class-prefix="icon-font" name="hege" color="#07c160" size="42"/>
      <van-icon class="status" v-if="dataList.conclusion == 0" class-prefix="icon-font" name="buhege" color="#ee0a24" size="42"/>     
      <ve-table                   
          rowKeyFieldName="rowKey"
          :fixed-header="true"
          :columns="columns"
          :table-data="tableData"
          :editOption="editOption"
          :rowStyleOption="rowStyleOption"
          border-y
        /> 
        <!-- :max-height="200"                       
          :virtual-scroll-option="virtualScrollOption" -->
    </div>
    <van-cell required center title="总体结论">
      <template #right-icon>
        <span>{{conclusion === '0' ? '不合格' : '合格'}}</span>
        <!-- <van-switch class="ml5" v-model="conclusion" active-value="1" inactive-value="0" size="18" />  -->
      </template>
    </van-cell>    
      <div class="title">复检人信息</div>
      <van-field label="复检人" input-align="right" readonly :value="dataList.recheck_emp_name" />      
      <div class="title">申请情况</div>
      <van-field label="申请人" input-align="right" readonly :value="dataList.inspect.inspect_emp_name" />
      <van-field label="申请原因" input-align="right" readonly :value="dataList.concession_acceptance_reason" /> 
      <van-field label="申请时间" input-align="right" readonly :value="dataList.concession_acceptance_time" /> 
      <div class="title">审批情况</div>
      <van-field label="审批人" input-align="right" readonly :value="dataList.approval_emp_name" />
      <van-field required label="备注" input-align="right" v-model="approvalRemark" placeholder="请填写审批备注" />  
      <van-cell title="附件上传">
        <van-uploader multiple
          accept="image/*"
          v-model="upload"
          :max-count="5"
          :after-read="afterRead" />
      </van-cell>
    <van-cell-group class="mt10 lr10">
      <van-row gutter="20">
        <van-col span="16"><van-button round block size="small" type="primary" native-type="submit" @click="clicksubBTn('1')" v-track="{triggerType:'click', title:'让步接收申请同意',currentUrl: $route.path,behavior: this._data, duration: new Date().getTime()}">同意</van-button></van-col>
        <van-col span="8"><van-button round block size="small" type="danger" native-type="submit" @click="clicksubBTn('0')" v-track="{triggerType:'click', title:'让步接收申请拒绝',currentUrl: $route.path,behavior: this._data, duration: new Date().getTime()}">拒绝</van-button></van-col>
      </van-row>
    </van-cell-group>
    <div class="mt10 btn-r">
      <van-button class="ml5" round plain size="small" type="default" to='/analysislist'>返回列表</van-button>  
    </div> 
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import { Cell, CellGroup, Step, Steps, Toast, Field, Picker,Popup,Button,Icon,Switch, Dialog ,Uploader } from 'vant'
import { ossUpload } from '@/common/fileUpload'
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Toast)
Vue.use(Field)
Vue.use(Picker)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Popup)
Vue.use(Switch)
Vue.use(Dialog)
Vue.use(Uploader)

export default {
  data() {
    return {
      value2: '',
      upload: [], //上传附件
      attach_info: [],
      equipmentName: '', //检测设备
      check_status: '',
      virtualScrollOption: {
          // 是否开启
          enable: true,
      },      
      dataList:{
        check_items:[],
        inspect:{
          type: {}
        }
      },    
      hgValue: '',
      hgId: '',
      showhgPicker: false,
      conclusion: '0',                       
      columns: [
          { field: "", key: "a", title: "序号", align: "center",width: "10%", renderBodyCell: ({ row, column, rowIndex }, h) => {
            return ++rowIndex
          }},            
          { field: "check_name", key: "check_name", title: "检测项", align: "center"},
          { field: "check_standard", key: "check_standard", title: "参考指标", align: "center" },
          { field: "check_value", key: "check_value", title: "检测值", align: "center" },
          { field: "single_conclusion", key: "single_conclusion", title: "单项结论", align: "center" },
      ],          
      rowStyleOption: {
        clickHighlight: false,
      },
      editOption: {
        // cell value change
        cellValueChange: ({ row, column }) => {              
          this.tableData.forEach(ele => {
            if(ele.check_item_id == row.check_item_id) {
              ele.check_value = row.check_value
            }
          })   
        },          
      },      
      tableData: [],
      approvalRemark: '', //审批备注                  
    }
  },
  created: function () {      
    var id = '';
    if(this.$route.query.id) {
      id = this.$route.query.id  
    } else {
      id = JSON.parse(localStorage.getItem('rbId'))    
    }      
    this.getDetail(id)    
  },
  methods: {
    ...mapActions('listData', ['asyncsetSampleType']),
    ...mapActions('router', ['asyncUser']),
    getDetail(id) {
      var that = this
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      this.$post('check/get-check-sample-info', 'api', { id: id}).then(res => {  
        Toast.clear();       
        var data = res.data          
        this.check_status = data.check_status          
        data.inspect.inspect_time = that.$common.initDate(data.inspect.inspect_time)
        data.check_time = that.$common.initDate(data.check_time)
        data.recheck_time = that.$common.initDate(data.recheck_time)
        data.inspect_recheck_time = that.$common.initDate(data.inspect_recheck_time) 
        data.concession_acceptance_time = that.$common.initDate(data.concession_acceptance_time) 
        this.dataList = data        
        data.check_items.forEach((ele,index) => {
          ele.rowKey = index
          if(ele.single_conclusion == '0') {
            ele.single_conclusion = '不合格'
          } else {
            ele.single_conclusion = '合格'
          }   
        })                     
        var equipmentName = []
        data.equipment_items.forEach(ele => {
          equipmentName.push(ele.equipment_name)
        })
        this.equipmentName = equipmentName.join(',')
        this.tableData = data.check_items
        return        
      }, err => {
        console.log(err)
        Toast.clear();
        this.$notify({ type: 'warning', message: err.message })
      })
    },    
    // 上传复检
    afterRead(upload) {
      upload.status = 'uploading'
      upload.message = '上传中...'
      ossUpload(upload).then((res) => {
        if (res.url) {
          this.attach_info.push({
            attach_name: res.name,
            attach_type: res.type,
            attach_path: res.path,
            attach_url: res.url
          })
          upload.status = 'done'
          upload.message = '上传成功'
        } else {
          upload.status = 'failed'
          upload.message = '上传失败'
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onOversize(file) {
      this.$notify({ type: 'warning', message: '文件大小不能超过 2M' })
    },
    // 提交
    clicksubBTn (status) {
      const that = this    
      let id = JSON.parse(localStorage.getItem('rbId'))
      if(this.$route.query.id) {
        id = this.$route.query.id  
      }
      that.$post('check/approval-compromise-receive', 'api', {
        id: id,
        conclusion: this.conclusion,
        approval_remark: this.approvalRemark,
        attach_info: this.attach_info,
        is_concession_acceptance: status
      }).then(res => {
        that.$notify({ type: 'success', message: res.message })
          that.$router.push({
            path: '/analysislist'
          })
        }, err => {
          that.$notify({ type: 'warning', message: err.message })
        })
      // Dialog.confirm({
      //   message: message,        
      // }).then(() => { 
      //   that.$post('check/approval-compromise-receive', 'api', params).then(res => {                    
      //   that.$notify({ type: 'success', message: res.message })
      //     that.$router.push({
      //       path: '/analysislist'            
      //     })               
      //   }, err => {
      //     console.log(err)        
      //     that.$notify({ type: 'warning', message: err.message })
      //   })
      // }).catch(() => {
      //   // on cancel
      // });  
      // console.log(params)       
    }
  }
}
</script>

<style scoped>
.status{
  position: absolute;
  top: -30px;
  right: 0px;
  width: 80px;
  height: 80px;
  z-index: 999;
 }
.sampledetail{
  padding-bottom: 60px;
  padding-top: 10px;
}
.title{
  font-size: 14px;
  color: #565656;
  margin: 10px 16px;
}
.van-step__icon h6, 
.van-step__title h6{
  margin: 0;
}
.title+p.gray{
  background-color: #fff;
}
.btn-r{
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
}
.lr10{
  margin-left: 10px;
  margin-right: 10px;
}
</style>
